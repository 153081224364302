import React from 'react';
import styled from 'styled-components';

import ActionIcon from './action-icon';
import NavigationIcon from './navigation-icon';
import ImageFooter from './image-footer';
import { Image } from '../../types/Image';
import { GatsbyImage } from 'gatsby-plugin-image';

const Root = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  display: flex;
`;

const ActionRow = styled.div`
  font-size: 1rem;
  margin: 1rem;
  justify-content: flex-end;
  display: flex;
`;

const ContentRow = styled.div`
  flex: 1 1;
  flex-direction: column;
  display: flex;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1 1;
  justify-content: center;
  align-content: center;
  overflow: hidden;

  .gatsby-image-wrapper {
    flex: 1 1;
    max-width: 1920px;
    max-height: 1920px;

    > div {
      width: auto !important;
      padding: 0 !important;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      min-width: 100%;
      object-fit: contain !important;
    }
  }
`;

const ActionOverlay = styled.div`
  position: absolute;
  z-index: 3000;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
`;

interface LightboxBodyProps {
  image: Image;
  index: number;
  count: number;
  hasNext: boolean;
  hasPrevious: boolean;
  gotoNext: () => void;
  gotoPrevious: () => void;
  onClose: () => void;
}

export const LightboxBody: React.FC<LightboxBodyProps> = ({
  image,
  index,
  count,
  hasNext,
  hasPrevious,
  gotoNext,
  gotoPrevious,
  onClose,
}) => {
  return (
    <Root>
      <ActionRow>
        <ActionIcon onClick={onClose} title="Close" iconClass="fa-times" />
      </ActionRow>
      <ContentRow>
        <ImageWrapper>
          {image.data && <GatsbyImage image={image.data} alt={`${image.exif?.title}`} />}
          <ActionOverlay>
            <NavigationIcon
              isVisible={hasPrevious}
              onClick={gotoPrevious}
              title="Previous"
              iconClass="fa-chevron-left"
            />
            <NavigationIcon isVisible={hasNext} onClick={gotoNext} title="Next" iconClass="fa-chevron-right" />
          </ActionOverlay>
        </ImageWrapper>
        <ImageFooter image={image} imageCount={count} index={index} />
      </ContentRow>
    </Root>
  );
};

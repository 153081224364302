import React from 'react';
import Modal from './modal';
import { useKeyPress } from '../../hooks/useKeyPress';
import { useLightboxNavigation } from '../../hooks/useLightboxNavigation';
import { Image } from '../../types/Image';
import { LightboxBody } from './lightbox-body';

interface LightboxProps {
  images?: Image[];
  onClose: () => void;
  currentImage: number;
}

const Lightbox: React.FC<LightboxProps> = ({ images, onClose, currentImage }) => {
  if (!images) {
    return <></>;
  }
  const { index, hasNext, hasPrevious, gotoNext, gotoPrevious } = useLightboxNavigation(
    currentImage,
    images.length - 1,
  );
  useKeyPress('ArrowRight', gotoNext);
  useKeyPress('ArrowLeft', gotoPrevious);
  useKeyPress('Escape', onClose);
  return (
    <Modal>
      <LightboxBody
        hasNext={hasNext}
        hasPrevious={hasPrevious}
        gotoNext={gotoNext}
        gotoPrevious={gotoPrevious}
        image={images[index]}
        onClose={onClose}
        index={index}
        count={images.length}
      />
    </Modal>
  );
};

export default Lightbox;

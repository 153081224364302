import React from 'react';
import styled from 'styled-components';

import ActionIcon from './action-icon';
import DummyActionIcon from './action-icon-dummy';

const Root = styled.div`
  font-size: 2rem;
  padding: 1rem;
  align-self: center !important;
`;

interface NavigationIconProps {
  isVisible: boolean;
  onClick: () => void;
  iconClass: string;
  title?: string;
}

const NavigationIcon: React.FC<NavigationIconProps> = ({ isVisible, onClick, title, iconClass }) => (
  <Root>{!isVisible ? <DummyActionIcon /> : <ActionIcon onClick={onClick} title={title} iconClass={iconClass} />}</Root>
);

export default NavigationIcon;

import React from 'react';
import styled from 'styled-components';

import { Image } from '../../types/Image';
import { THEME } from '../../constants/theme';
import { GatsbyImage } from 'gatsby-plugin-image';

interface ContainerProps {
  rows?: number;
  columns?: number;
}

const Container = styled.div<ContainerProps>`
  transition: all 2s ease-in-out;
  box-shadow: 3px 3px 10px ${THEME.gray};
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;
  padding: 1rem;
  border: 4px ${THEME.gray} solid;

  ${({ rows }) => rows && `grid-row: span ${rows};`}
  ${({ columns }) => columns && `grid-column: span ${columns};`} 
  
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  :hover {
    box-shadow: 8px 8px 20px ${THEME.gray};
  }

  &::before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 75%;
  }

  &::after {
    content: '';
    display: table;
    clear: both;
  }
`;

interface RootProps {
  maxWidth?: number;
}

const ImageWrapper = styled.div<RootProps>`
  border-radius: 0.2rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;

  .gatsby-image-wrapper {
    flex: 1;
    border: 3px #aaa inset;
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}%;`}
    ${({ maxWidth }) => !maxWidth && 'margin-bottom: 0.5rem;'}
  }
`;

interface GalleryImageProps {
  image: Image;
  onClick: () => void;
}

const GalleryImage: React.FC<GalleryImageProps> = ({ image, onClick }) => {
  let rows: number | undefined;
  let columns: number | undefined;
  let maxWidth: number | undefined;
  // const aspectRatio = image.node.childImageSharp?.fluid?.aspectRatio;
  const aspectRatio = (image.data?.width || 1)/ (image.data?.height || 1);
  const frameAspectRatio = 0.75;
  if (aspectRatio) {
    if (aspectRatio < 1) {
      //   rows = Math.ceil(1 / aspectRatio);
      maxWidth = Math.round(aspectRatio * 100 * frameAspectRatio);
    } else if (aspectRatio === 1) {
      maxWidth = Math.round(100 * frameAspectRatio);
    } else if (aspectRatio < 1.5) {
      maxWidth = Math.round(100 * 0.95);
    }
  }
  return (
    <Container columns={columns} rows={rows} onClick={onClick}>
      <ImageWrapper maxWidth={maxWidth}>
        {image.data && <GatsbyImage image={image.data} alt={`${image.exif?.title}`} />}
      </ImageWrapper>
    </Container>
  );
};
export default GalleryImage;

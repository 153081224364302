import { FluidImageType } from '../types/FluidImageType';
import { FluidObject } from 'gatsby-image';

export const toUndefinedBase64 = (image?: FluidImageType | null): FluidObject | undefined =>
  image ? { ...image, base64: image.base64 || undefined } : undefined;

interface EdgeNode {
  name: string;
}

export const sortByName = (a: EdgeNode, b: EdgeNode) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

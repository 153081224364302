import { useState } from 'react';

export const useLightboxNavigation = (startIndex: number, maxIndex: number) => {
  const [index, setIndex] = useState(startIndex);
  const hasNext = index < maxIndex;
  const hasPrevious = index > 0;
  const gotoNext = () => {
    if (hasNext) {
      setIndex(index + 1);
    }
  };
  const gotoPrevious = () => {
    if (hasPrevious) {
      setIndex(index - 1);
    }
  };
  return {
    index,
    hasNext,
    hasPrevious,
    gotoNext,
    gotoPrevious,
  };
};

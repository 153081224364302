import React from 'react';
import styled from 'styled-components';

import { THEME } from '../../constants/theme';

const Icon = styled.span`
  color: ${THEME.contrastText};
  cursor: pointer;
  padding: 1rem;
`;

interface ActionIconProps {
  iconClass: string;
  title?: string;
  onClick: () => void;
}

const ActionIcon: React.FC<ActionIconProps> = ({ iconClass, title, onClick }) => (
  <Icon className={`fas ${iconClass}`} title={title} onClick={onClick} />
);

export default ActionIcon;

import { useEffect } from 'react';

export const useKeyPress = (targetKey: string, fn: Function) => {
  function downHandler(event: KeyboardEvent) {
    const { key } = event;
    if (key === targetKey) {
      fn();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  });
};

import React from 'react';
import styled from 'styled-components';

import { Image } from '../../types/Image';
import { THEME } from '../../constants/theme';

const formatExposure = (exposure: number) => {
  if (exposure < 0.5) {
    return `1/${Math.round(1 / exposure)}`;
  }
  return exposure;
};

const buildCaption = (image: Image) => {
  if (image?.exif) {
    const { title, focalLength, exposure, iso, aperture } = image.exif;
    const caption = [];
    let pictureTitle = '';
    if (title) {
      pictureTitle = `${title}\n`;
    }
    if (focalLength) {
      caption.push(`Brennweite: ${focalLength}mm`);
    }
    if (exposure) {
      caption.push(`Belichtungszeit: ${formatExposure(exposure)} sek.`);
    }
    if (aperture) {
      caption.push(`Blende: ${aperture}`);
    }
    if (iso) {
      caption.push(`ISO: ${iso}`);
    }
    return `${pictureTitle}${caption.join(' | ')}`;
  }
  return undefined;
};

const Root = styled.div`
  font-size: 0.75rem;
  margin: 1rem;
  color: ${THEME.contrastText};
  flex-direction: row;
  display: flex;
`;

const Pre = styled.div`
  white-space: pre-wrap;
  flex: 1 1;
`;

interface ImageFooterProps {
  image: Image;
  index: number;
  imageCount: number;
}

const ImageFooter: React.FC<ImageFooterProps> = ({ image, index, imageCount }) => (
  <Root>
    <Pre>{buildCaption(image)}</Pre>
    <div>{`${index + 1} / ${imageCount}`}</div>
  </Root>
);

export default ImageFooter;

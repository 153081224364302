import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const createStyleTag = () => {
  let tag = document.createElement('style');
  tag.type = 'text/css';
  return tag;
};

const injectStyle = (tag: HTMLStyleElement, css: string) => {
  // @ts-ignore: attribute styleSheet does not exist on HTMLStyleElement
  if (tag.styleSheet) {
    // @ts-ignore: attribute styleSheet does not exist on HTMLStyleElement
    tag.styleSheet.cssText = css;
  } else {
    tag.appendChild(document.createTextNode(css));
  }
};

const insertStyleTag = (tag: HTMLStyleElement) => {
  const head = document.head || document.getElementsByTagName('head')[0];
  head.appendChild(tag);
};

const calculateRightPadding = () => {
  // @ts-ignore: Attribute paddingRight does not exist on HtmlElement
  const currentPadding = parseInt(document.body.paddingRight, 10) || 0;
  const clientWidth = document.body ? document.body.clientWidth : 0;
  return window.innerWidth - clientWidth + currentPadding || 0;
};

const getDocumentHeight = () => {
  return document.body.clientHeight;
};

const createBodyStyle = () => {
  const height = getDocumentHeight();
  const paddingRight = calculateRightPadding();
  const bodyStyle = `body {
      box-sizing: border-box !important;
      overflow: hidden !important;
      position: relative !important;
      height: ${height}px !important;
      padding-right: ${paddingRight}px !important;
    }`;
  return bodyStyle;
};

const Modelframe = styled.div`
  height: 100%;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2001;
  background: rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  overflow: hidden;
`;

const Modal: React.FC<{}> = ({ children }) => {
  useEffect(() => {
    const bodyStyle = createBodyStyle();
    const styleTag = createStyleTag();
    injectStyle(styleTag, bodyStyle);
    insertStyleTag(styleTag);
    return () => {
      styleTag?.parentNode?.removeChild(styleTag);
    };
  }, []);

  return createPortal(
    <div>
      <Modelframe>{children}</Modelframe>
    </div>,
    document.body,
  );
};

export default Modal;

import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Lightbox from '../lightbox/lightbox';
import GalleryImage from './gallery-image';
import { useGallery } from '../../hooks/useGallery';
import { Title } from '../common/title';
import { Image } from '../../types/Image';

const GalleryRoot = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  margin-bottom: 2rem;
  grid-gap: 3rem;
  align-items: flex-end;

  @media (max-width: 858px) {
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  }
`;

interface GalleryProps {
  thumbnails: GatsbyTypes.GalleryThumbnailFragmentFragment;
  images: GatsbyTypes.GalleryImageFragmentFragment;
  title?: string;
}

const Gallery: React.FC<GalleryProps> = ({ thumbnails, images, title }) => {
  const { orderedImages, orderedThumbnails, closeLightbox, openLightbox, lightboxIndex, isLightboxOpen } = useGallery(
    thumbnails,
    images,
  );
  if (!orderedImages) {
    return <div />;
  }
  return (
    <div>
      {title && <Title>{title}</Title>}
      <GalleryRoot>
        {orderedThumbnails.map((image, imgIdx) => (
          <GalleryImage key={imgIdx} image={image} onClick={() => openLightbox(imgIdx)} />
        ))}
      </GalleryRoot>
      {isLightboxOpen && <Lightbox images={orderedImages} onClose={closeLightbox} currentImage={lightboxIndex} />}
    </div>
  );
};

export default Gallery;

export const query = graphql`
  fragment GalleryImageFragment on FileConnection {
    edges {
      node {
        fields {
          exif {
            title
            focalLength
            exposure
            iso
            aperture
          }
        }
        id
        name
        childImageSharp {
          gatsbyImageData(width: 2048)
        }
      }
    }
  }
  fragment GalleryThumbnailFragment on FileConnection {
    edges {
      node {
        id
        name
        childImageSharp {
          gatsbyImageData(width: 320)
        }
      }
    }
  }
`;

import React from 'react';
import { graphql, PageProps } from 'gatsby';

import { PortfolioRoot } from '../../components/portfolio/portfolio-root';
import Layout from '../../components/site-layout/site-layout';
import Gallery from '../../components/image-gallery/gallery';
import { Head } from '../../components/site-layout/head';
import { getImage } from 'gatsby-plugin-image';

const LandscapePortfolioPage: React.FC<PageProps<GatsbyTypes.LandscapePortfolioQueryQuery>> = ({ data, location }) => {
  const headerImage =
    data.headerImage && data.headerImage.childImageSharp
      ? getImage(data.headerImage.childImageSharp.gatsbyImageData)
      : undefined;

  return (
    <Layout location={location} image={headerImage}>
      <Head title="Landscape Portfolio" imageSrc={headerImage?.images.fallback?.src} />
      <PortfolioRoot>
        <Gallery thumbnails={data.smallImages} images={data.bigImages} title="Landscape Portfolio" />
      </PortfolioRoot>
    </Layout>
  );
};

export default LandscapePortfolioPage;

export const pageQuery = graphql`
  query LandscapePortfolioQuery {
    headerImage: file(relativePath: { eq: "images/lp-portfolio-landscape.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 4000)
      }
    }
    smallImages: allFile(filter: { relativePath: { regex: "/portfolio/landscape-images/" } }) {
      ...GalleryThumbnailFragment
    }
    bigImages: allFile(filter: { relativePath: { regex: "/portfolio/landscape-images/" } }) {
      ...GalleryImageFragment
    }
  }
`;

import { getImage } from 'gatsby-plugin-image';
import { useState } from 'react';
import { Image } from '../types/Image';
import { sortByName } from '../util/image';

export const useGallery = (thumbnails: GatsbyTypes.GalleryThumbnailFragmentFragment,
  images: GatsbyTypes.GalleryImageFragmentFragment) => {
  const orderedThumbnails: Image[] = thumbnails.edges?.map(edge => ({
    id: edge.node.id,
    name: edge.node.name,
    data: getImage(edge.node.childImageSharp!.gatsbyImageData)
  })).sort(sortByName) || [];
  const orderedImages = images.edges?.map(edge => ({
    id: edge.node.id,
    name: edge.node.name,
    data: getImage(edge.node.childImageSharp!.gatsbyImageData),
    exif: {...edge.node.fields?.exif}
  })).sort(sortByName) ||[];
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  return {
    orderedThumbnails,
    orderedImages,
    isLightboxOpen,
    lightboxIndex,
    openLightbox: (index: number) => {
      setIsLightboxOpen(true);
      setLightboxIndex(index);
    },
    closeLightbox: () => setIsLightboxOpen(false),
  };
};
